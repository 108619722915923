<template>
    <div>
        <div class="flex flex-row justify-between items-center space-x-4">
            <div class="input-container w-full">
                <label>Le nom de la liste {{ DEFAULT_LANG }}</label>
                <div class="c__input">
                    <input
                        disabled
                        type="text"
                        name="word"
                        :value="wordGroup.text" />
                </div>
                <p
                    class="error"
                    v-if="errors.text">
                    {{ errors.text }}
                </p>
            </div>
            <div class="input-container">
                <div class="c__input flex flex-row justify-start items-center">
                    <input
                        id="active"
                        type="checkbox"
                        name="active"
                        v-model="wordGroup.active" />
                    <label for="active">Activé</label>
                </div>
            </div>
        </div>

        <div class="input-container">
            <label>Ordre</label>
            <div class="c__input">
                <input
                    type="number"
                    name="orderIdx"
                    v-model="wordGroup.orderIdx" />
            </div>
            <p
                class="error"
                v-if="errors.orderIdx">
                {{ errors.orderIdx }}
            </p>
        </div>

        <div class="input-container">
            <label>Allo Prof External code</label>
            <div class="c__input">
                <input
                    type="text"
                    name="externalCode"
                    v-model="wordGroup.externalCode" />
            </div>
            <p
                class="error"
                v-if="errors.externalCode">
                {{ errors.externalCode }}
            </p>
        </div>

        <div class="input-container">
            <div class="c__input flex flex-row justify-start items-center">
                <input
                    id="isCurrent"
                    type="checkbox"
                    name="isCurrent"
                    v-model="wordGroup.isCurrent" />
                <label for="isCurrent">Liste de la semaine</label>
            </div>
        </div>

        <item-select
            select
            hide-empty
            v-model="wordGroup.difficulty"
            :data="Object.values(DIFFICULTY)"
            label="Niveau"
            :label-key="(item) => $t(`global.difficulty.${item}`)"
            :value-key="null"
            :error="errors.difficulty" />

        <item-select
            select
            v-model="wordGroup.grade"
            :data="Object.values(GRADES)"
            label="Année scolaire"
            :error="errors.grade"
            :value-key="null"
            :label-key="(item) => item" />

        <div class="input-container">
            <label>Image de la liste de mot</label>
            <UploadImage
                :path="wordGroup.imagePath"
                :url="wordGroup.imageUrl"
                file-folder="word-groups"
                @upload="onUpload"
                preview-class="w-24 h-24 object-contain block" />
            <p
                class="error"
                v-if="errors.imagePath">
                {{ errors.imagePath }}
            </p>
        </div>

        <div
            v-for="traduction in wordGroup.traductions"
            :key="traduction.lang"
            class="bg-gray-200 p-6 my-6">
            <div class="flex flex-row justify-between items-center">
                <h3>
                    {{ $t(`global.langs.${traduction.lang}`) }}
                </h3>
                <div class="input-container">
                    <div class="c__input flex flex-row justify-start items-center">
                        <input
                            :id="`traduction-${traduction.lang}-active`"
                            type="checkbox"
                            name="active"
                            v-model="traduction.active" />
                        <label :for="`traduction-${traduction.lang}-active`">Activé</label>
                    </div>
                </div>
            </div>
            <div class="md:grid gap-3 grid-cols-2">
                <div class="input-container">
                    <label>Le nom de la liste</label>
                    <div class="c__input">
                        <input
                            v-lowercase
                            type="text"
                            name="word"
                            v-model="traduction.text" />
                    </div>
                    <p
                        class="error"
                        v-if="errors.text">
                        {{ errors.text }}
                    </p>
                </div>
            </div>
        </div>

        <p
            v-if="wordGroup.smartType"
            class="error">
            La liste de mots est intelligente et les mots seront ajoutés automatiquement.
        </p>
        <div v-else>
            <div
                v-if="wordGroup.id"
                class="py-4">
                <button
                    class="btn"
                    @click.prevent="openSmartUpload">
                    Créer des mots automatiquement (Smart Upload)
                </button>
            </div>

            <items-select
                :empty-search="false"
                @search="searchWords"
                v-model="words"
                :error="errors.wordIds"
                label="Mots"
                :data="searchedWords"
                :label-key="(word) => getLabelForWord(word)"
                value-key="id">
                <template v-slot:item="slotProps">
                    <p>
                        {{ getLabelForWord(slotProps.item) }}
                    </p>
                    &nbsp;
                    <a
                        class="underline"
                        target="_blank"
                        :href="`/words/${slotProps.item.uuid}`">
                        Voir
                    </a>
                </template>
            </items-select>
        </div>
    </div>

    <smart-words-upload-modal
        :word-group="wordGroup"
        @created="onSmartUploadCreated"
        @close="showSmartUploadModal = false"
        v-if="showSmartUploadModal && wordGroup.id" />
</template>

<script>
import Icons from "components/globals/Icons"
import ItemsSelect from "components/forms/ItemsSelect"
import ajax from "config/ajax"
import ItemSelect from "components/forms/ItemSelect"
import UploadImage from "components/uploads/UploadImage"
import { DEFAULT_LANG, DIFFICULTY, GRADES, WORD_GROUP_CATEGORIES } from "@globals/constants"
import SmartWordsUploadModal from "components/modals/SmartWordsUploadModal"

export default {
    name: "word-group-form",
    emits: ["submit"],
    components: {
        SmartWordsUploadModal,
        UploadImage,
        ItemSelect,
        ItemsSelect,
        Icons
    },
    props: {
        wordGroup: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            GRADES,
            DEFAULT_LANG,
            DIFFICULTY,
            WORD_GROUP_CATEGORIES,

            words: [],
            searchedWords: [],

            showSmartUploadModal: false
        }
    },
    watch: {
        defaultLangWord(newValue) {
            this.wordGroup.text = newValue
        },
        words(newValue) {
            //sync with word ids
            this.wordGroup.wordIds = newValue.map((w) => w.id)
        }
    },
    created() {
        if (this.wordGroup.uuid) {
            ajax.get(`/word-groups/uuid/${this.wordGroup.uuid}/words`).then((res) => {
                this.words = res.words
            })
        }
    },
    computed: {
        defaultLangWord() {
            let traduction = this.$store.getters.getTraduction(this.wordGroup.traductions)
            if (!traduction) return ""
            return traduction.text
        }
    },
    methods: {
        getLabelForWord(word) {
            if (!word.traductions || word.traductions.length === 0) {
                return `${word.text} (#${word.id})`
            }

            let traduction = this.$store.getters.getTraduction(word.traductions)
            if (!traduction) {
                return `${word.text} (#${word.id})`
            }
            if (!traduction.text) {
                return `${word.text} (#${word.id})`
            }
            return `${traduction.text} (#${word.id})`
        },

        searchWords(searchValue) {
            ajax.get(`/words/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.searchedWords = res.words
            })
        },
        openSmartUpload() {
            this.showSmartUploadModal = true
        },
        onSmartUploadCreated(words) {
            //merge
            this.words = this.words.concat(words)
            this.showSmartUploadModal = false
            this.$nextTick(() => {
                this.$emit("submit") //save
            })
        },
        onUpload(e) {
            // has delete image
            if (e.path === null) {
                this.wordGroup.imagePath = null
                this.wordGroup.imageUrl = null

                return
            }

            this.wordGroup.imagePath = e.path
            this.wordGroup.imageUrl = e.url
        }
    }
}
</script>
