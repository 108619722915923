<template>
    <div class="words">
        <pagination-layout
            create-title="Créer un nouveau mot"
            create-url="/words/create"
            title="Mots"
            :data="words"
            @search="onSearch">
            <template v-slot:header>
                <custom-th> ID </custom-th>
                <custom-th> Mot </custom-th>
                <custom-th> Niveau </custom-th>
                <custom-th> Année scolaire </custom-th>
                <custom-th> Type </custom-th>
                <custom-th>
                    <span class="sr-only">Modifier</span>
                </custom-th>
            </template>
            <template v-slot:data="{ currentPage }">
                <tr
                    v-for="word in currentPage"
                    :key="word.id">
                    <custom-td>
                        {{ word.id }}
                    </custom-td>
                    <custom-td>
                        {{ word.text }}
                    </custom-td>
                    <custom-td>
                        {{ $t(`global.difficulty.${word.difficulty}`) }}
                    </custom-td>
                    <custom-td>
                        {{ word.grade }}
                    </custom-td>
                    <custom-td>
                        <span
                            v-for="(type, idx) in word.type"
                            :key="type">
                            {{ idx > 0 ? ", " : "" }}{{ $t(`global.wordTypes.${type}`) }}
                        </span>
                    </custom-td>
                    <custom-td>
                        <router-link
                            :to="`/words/${word.uuid}`"
                            class="text-red-logo hover:text-red-logo-dark text-right w-full block">
                            Modifier
                        </router-link>
                    </custom-td>
                </tr>
            </template>
        </pagination-layout>
        <!-- <ImportWordsModal @close="showImportWordsModal = false" v-if="showImportWordsModal"/> -->
    </div>
</template>

<script>
import { sortBy } from "lodash"
import ImportWordsModal from "components/modals/ImportWordsModal"
import PaginationLayout from "views/layouts/PaginationLayout"
import CustomTh from "components/tables/CustomTh"
import CustomTd from "components/tables/CustomTd"
import { LANGS } from "@globals/constants"
import ajax from "config/ajax"

export default {
    name: "words",
    components: {
        CustomTd,
        CustomTh,
        PaginationLayout,
        // eslint-disable-next-line vue/no-unused-components
        ImportWordsModal
    },
    data() {
        return {
            LANGS,

            words: [],
            showImportWordsModal: false
        }
    },
    methods: {
        importWords() {
            this.showImportWordsModal = true
        },
        onSearch(searchValue) {
            ajax.get(`/words/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.words = sortBy(res.words, "text")
            })
        }
    }
}
</script>
