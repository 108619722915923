<template>
    <div class="school-users">
        <pagination-layout
            title="Professeurs/Manager"
            create-title="Créer un nouveau utilisateur"
            create-url="/school-users/create"
            @search="onSearch"
            :data="schoolUsers">
            <template v-slot:header>
                <custom-th> Nom </custom-th>
                <custom-th> Courriel </custom-th>
                <custom-th> Rôle </custom-th>
                <custom-th> À un forfait </custom-th>
                <custom-th>
                    <span class="sr-only">Modifier</span>
                </custom-th>
            </template>

            <template #form>
                <div class="pt-2 flex flex-row justify-start items-center space-x-2">
                    <button
                        type="button"
                        :class="`${selectedRole === USER_ROLES.PARENT ? 'btn' : 'btn--outline'}`"
                        @click.prevent="filterByRole(USER_ROLES.PARENT)">
                        Parent
                    </button>
                    <button
                        type="button"
                        class="btn"
                        :class="`${selectedRole === USER_ROLES.TEACHER ? 'btn' : 'btn--outline'}`"
                        @click.prevent="filterByRole(USER_ROLES.TEACHER)">
                        Professeur
                    </button>
                    <button
                        type="button"
                        class="btn"
                        :class="`${selectedRole === USER_ROLES.SCHOOL_MANAGER ? 'btn' : 'btn--outline'}`"
                        @click.prevent="filterByRole(USER_ROLES.SCHOOL_MANAGER)">
                        Directeur d'école
                    </button>
                </div>
            </template>

            <template v-slot:data="{ currentPage }">
                <tr
                    v-for="schoolUser in currentPage"
                    :key="schoolUser.id">
                    <custom-td>
                        {{ schoolUser.name }}
                    </custom-td>
                    <custom-td>
                        {{ schoolUser.email }}
                    </custom-td>
                    <custom-td>
                        <span v-if="schoolUser.role === USER_ROLES.PARENT"> Parent </span>
                        <span v-else-if="schoolUser.role === USER_ROLES.TEACHER"> Professeur </span>
                        <span v-else-if="schoolUser.role === USER_ROLES.SCHOOL_MANAGER"> Directeur d'école </span>
                    </custom-td>
                    <custom-td>
                        {{ schoolUser.licenseId ? "Oui" : "Non" }}
                    </custom-td>
                    <custom-td>
                        <router-link
                            :to="`/school-users/${schoolUser.uuid}`"
                            class="text-red-logo hover:text-red-logo-dark text-right w-full block">
                            Modifier
                        </router-link>
                    </custom-td>
                </tr>
            </template>
        </pagination-layout>
    </div>
</template>

<script>
import PaginationLayout from "views/layouts/PaginationLayout"
import CustomTd from "components/tables/CustomTd"
import CustomTh from "components/tables/CustomTh"
import { sortBy } from "lodash"
import ajax from "config/ajax"
import { USER_ROLES } from "@globals/constants"

//todo we cant edit parent from here...

export default {
    name: "school-users",
    computed: {
        USER_ROLES() {
            return USER_ROLES
        }
    },
    components: {
        CustomTh,
        PaginationLayout,
        CustomTd
    },
    data() {
        return {
            schoolUsers: [],
            selectedRole: "",
            lastSearchValue: ""
        }
    },
    methods: {
        filterByRole(role) {
            if (this.selectedRole === role) {
                this.selectedRole = ""
                this.onSearch(this.lastSearchValue)
                return
            }
            this.selectedRole = role
            this.onSearch(this.lastSearchValue)
        },
        onSearch(searchValue) {
            this.lastSearchValue = searchValue
            ajax.get(`/school-users/search`, {
                params: {
                    search: searchValue,
                    role: this.selectedRole
                }
            }).then((res) => {
                this.schoolUsers = sortBy(res.schoolUsers, "name")
            })
        }
    }
}
</script>
