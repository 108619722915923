<template>
    <div>
        <div class="flex flex-row justify-between items-center space-x-4">
            <div class="input-container w-full">
                <label>Le mot ({{ DEFAULT_LANG }})</label>
                <div class="c__input">
                    <input
                        disabled
                        type="text"
                        name="word"
                        :value="word.text" />
                </div>
                <p
                    class="error"
                    v-if="errors.word">
                    {{ errors.word }}
                </p>
            </div>
            <div class="input-container">
                <div class="c__input flex flex-row justify-start items-center">
                    <input
                        id="active"
                        type="checkbox"
                        name="active"
                        v-model="word.active" />
                    <label for="active">Activé</label>
                </div>
            </div>
        </div>

        <item-select
            select
            hide-empty
            v-model="word.difficulty"
            :data="Object.values(DIFFICULTY)"
            label="Niveau"
            :label-key="(item) => $t(`global.difficulty.${item}`)"
            :value-key="null"
            :error="errors.difficulty" />

        <item-select
            select
            v-model="word.grade"
            :data="Object.values(GRADES)"
            label="Année scolaire"
            :error="errors.grade"
            :value-key="null"
            :label-key="(item) => item" />

        <items-select
            :empty-search="false"
            v-model="selectedWordTypes"
            :data="wordTypes"
            label="Type de mot"
            :error="errors.type" />

        <div class="input-container">
            <label>Image du mot</label>
            <UploadImage
                :path="word.imagePath"
                :url="word.imageUrl"
                file-folder="words"
                @upload="onUpload"
                preview-class="w-24 h-24 object-contain block" />
            <p
                class="error"
                v-if="errors.imagePath">
                {{ errors.imagePath }}
            </p>
            <div class="mt-3">
                <button
                    type="button"
                    class="btn--text"
                    @click.prevent="getImage">
                    Générer l'image
                </button>
            </div>
        </div>

        <div>
            <div
                v-for="traduction in traductionsArr"
                :key="traduction.lang"
                class="bg-gray-200 p-6 my-6">
                <div class="flex flex-row justify-between items-center">
                    <h3>
                        {{ $t(`global.langs.${traduction.lang}`) }}
                    </h3>
                    <div class="input-container">
                        <div class="c__input flex flex-row justify-start items-center">
                            <input
                                :id="`traduction-${traduction.lang}-active`"
                                type="checkbox"
                                name="active"
                                v-model="traduction.active" />
                            <label :for="`traduction-${traduction.lang}-active`">Activé</label>
                        </div>
                    </div>
                </div>
                <div class="md:grid gap-3 grid-cols-2">
                    <div class="input-container">
                        <label>Le mot</label>
                        <div class="c__input">
                            <input
                                v-lowercase
                                type="text"
                                name="word"
                                v-model="traduction.text" />
                        </div>
                        <p
                            class="error"
                            v-if="errors.word">
                            {{ errors.word }}
                        </p>
                    </div>

                    <div class="input-container">
                        <label>Règle</label>
                        <div class="c__input">
                            <textarea
                                rows="5"
                                @keyup.enter.stop
                                name="rule"
                                v-model="traduction.rule"></textarea>
                        </div>
                        <p
                            class="error"
                            v-if="errors.rule">
                            {{ errors.rule }}
                        </p>
                    </div>
                </div>

                <div class="md:grid gap-3 grid-cols-2">
                    <div class="input-container">
                        <label>Définition</label>
                        <div class="c__input">
                            <textarea
                                rows="5"
                                @keyup.enter.stop
                                name="description"
                                v-model="traduction.description"></textarea>
                        </div>
                        <p
                            class="error"
                            v-if="errors.description">
                            {{ errors.description }}
                        </p>
                        <div class="mt-3">
                            <button
                                type="button"
                                class="btn--text"
                                @click.prevent="getDefinition(traduction.lang)">
                                Générer la définition
                            </button>
                        </div>
                    </div>

                    <!--  <div class="input-container">
              <label>Image du mot</label>
              <UploadImage
                  :path="traduction.imagePath"
                  :url="traduction.imageUrl"
                  file-folder="words/images"
                  @upload="e => onUpload(traduction.lang, e)"
                  preview-class="w-24 h-24 object-contain block"
              />
              <p class="error" v-if="errors.imagePath">{{ errors.imagePath }}</p>
              <div class="mt-3">
                <button type="button" class="btn--text" @click.prevent="getImage(traduction.lang)">Générer</button>
              </div>
            </div> -->
                    <div class="input-container">
                        <label>Son du mot</label>
                        <audio
                            controls
                            ref="audioTag"
                            v-if="traduction.soundPath">
                            <source
                                :src="traduction.soundUrl"
                                :type="`${traduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                        </audio>
                        <p
                            class="text-yellow-400 text-sm pt-2"
                            v-else>
                            Aucun son pour l'instant
                        </p>
                        <p
                            class="error"
                            v-if="errors.soundPath">
                            {{ errors.soundPath }}
                        </p>
                    </div>
                </div>
                <div class="word__sentences border-t border-gray-400 mt-4 pt-4">
                    <div class="input-container">
                        <label
                            >Phrases<br />
                            <span class="text-xs"
                                >Une phrase contient le mot. Ex: Mot: "Bas" Phrase: Tu as de beaux
                                <span class="underline">bas</span> dans tes pieds.</span
                            ><br />
                            <span class="text-xs">(Doit contenir ____ à la place du mot pour être valide)</span></label
                        >
                        <div
                            class="mb-2 p-2 border border-gray-300"
                            v-for="(sentence, sentenceIdx) in traduction.sentences"
                            :key="sentence.id">
                            <div class="c__input flex flex-row justify-between items-center">
                                <div class="relative w-full">
                                    <input
                                        :name="`sentence-${sentence.id}`"
                                        v-model="sentence.text"
                                        type="text"
                                        class="w-full" />
                                    <div
                                        class="absolute top-0 bottom-0 right-0 flex flex-col justify-center items-center pr-4">
                                        <Icons
                                            icon="check-circle"
                                            class="w-4 h-4 text-green-500"
                                            v-if="sentenceIsValid(traduction.text, sentence.text)" />
                                        <Icons
                                            icon="exclamation-circle"
                                            class="w-4 h-4 text-yellow-500"
                                            v-else />
                                    </div>
                                </div>
                                <button
                                    class="btn h-full ml-2"
                                    type="button"
                                    @click.prevent="removeSentence(traduction.lang, sentenceIdx)">
                                    <Icons
                                        class="h-4 w-auto inline-block text-white"
                                        icon="trash" />
                                </button>
                            </div>
                            <div class="flex flex-row justify-start items-start space-x-3">
                                <item-select
                                    select
                                    hide-empty
                                    v-model="sentence.difficulty"
                                    :data="Object.values(DIFFICULTY)"
                                    label="Niveau"
                                    :label-key="(item) => $t(`global.difficulty.${item}`)"
                                    :value-key="null"
                                    :error="errors.difficulty" />

                                <item-select
                                    select
                                    v-model="sentence.grade"
                                    :data="Object.values(GRADES)"
                                    label="Année scolaire"
                                    :error="errors.grade"
                                    :value-key="null"
                                    :label-key="(item) => item" />

                                <div class="input-container">
                                    <label>Son de la phrase</label>
                                    <UploadSound
                                        :path="sentence.soundPath"
                                        :url="sentence.soundUrl"
                                        file-folder="words/sentences/sounds"
                                        @upload="onSentenceAudioUpload($event, traduction.lang, sentenceIdx)" />
                                    <p
                                        class="error"
                                        v-if="errors.soundPath">
                                        {{ errors.soundPath }}
                                    </p>
                                </div>
                            </div>

                            <p
                                class="error"
                                v-if="errors.sentences && errors.sentences[sentenceIdx]">
                                {{ errors.sentences[sentenceIdx] }}
                            </p>
                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn"
                        @click.prevent="addSentence(traduction.lang)">
                        Ajouter une phrase
                        <Icons
                            class="h-4 w-auto inline-block ml-2 text-white"
                            icon="plus" />
                    </button>
                </div>
                <div class="word__questions border-t border-gray-400 mt-4 pt-4">
                    <div class="input-container">
                        <label
                            >Questions<br />
                            <span class="text-xs"
                                >Une question ne contient pas le mot. Ex: Mot: "Bas" Phrase: Qu'est-ce que tu portes à
                                tes pieds?
                            </span>
                        </label>
                        <div
                            class="mb-2 p-2 border border-gray-300"
                            v-for="(question, questionIdx) in traduction.questions"
                            :key="question.id">
                            <div class="c__input flex flex-row justify-between items-center">
                                <div class="relative w-full">
                                    <input
                                        :name="`question-${question.id}`"
                                        v-model="question.text"
                                        type="text"
                                        class="w-full" />
                                </div>
                                <button
                                    class="btn h-full ml-2"
                                    type="button"
                                    @click.prevent="removeQuestion(traduction.lang, questionIdx)">
                                    <Icons
                                        class="h-4 w-auto inline-block text-white"
                                        icon="trash" />
                                </button>
                            </div>
                            <div class="flex flex-row justify-start items-start space-x-3">
                                <item-select
                                    select
                                    hide-empty
                                    v-model="question.difficulty"
                                    :data="Object.values(DIFFICULTY)"
                                    label="Niveau"
                                    :label-key="(item) => $t(`global.difficulty.${item}`)"
                                    :value-key="null"
                                    :error="errors.difficulty" />

                                <item-select
                                    select
                                    v-model="question.grade"
                                    :data="Object.values(GRADES)"
                                    label="Année scolaire"
                                    :error="errors.grade"
                                    :value-key="null"
                                    :label-key="(item) => item" />

                                <div class="input-container">
                                    <label>Son de la question</label>
                                    <UploadSound
                                        :path="question.soundPath"
                                        :url="question.soundUrl"
                                        file-folder="words/questions/sounds"
                                        @upload="onQuestionAudioUpload($event, traduction.lang, questionIdx)" />
                                    <p
                                        class="error"
                                        v-if="errors.soundPath">
                                        {{ errors.soundPath }}
                                    </p>
                                </div>
                            </div>

                            <p
                                class="error"
                                v-if="errors.questions && errors.questions[questionIdx]">
                                {{ errors.questions[questionIdx] }}
                            </p>
                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn"
                        @click.prevent="addQuestion(traduction.lang)">
                        Ajouter une question
                        <Icons
                            class="h-4 w-auto inline-block ml-2 text-white"
                            icon="plus" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import UploadImage from "components/uploads/UploadImage"
import { v4 } from "uuid"
import ajax from "config/ajax"
import ItemSelect from "components/forms/ItemSelect"
import { DEFAULT_LANG, DIFFICULTY, GRADES, LANGS, WORD_TYPES } from "@globals/constants"
import UploadSound from "components/uploads/UploadSound"
import ItemsSelect from "components/forms/ItemsSelect.vue"

export default {
    name: "word-form",
    components: {
        ItemsSelect,
        UploadSound,
        ItemSelect,
        UploadImage,
        Icons
    },
    props: {
        word: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            GRADES,
            DEFAULT_LANG,
            DIFFICULTY,

            wordTypes: Object.values(WORD_TYPES).map((type) => ({
                label: this.$t(`global.wordTypes.${type}`),
                value: type
            })),

            selectedWordTypes: []
        }
    },
    computed: {
        traductionsArr() {
            //only show FR for now
            return this.word.traductions.filter((t) => t.lang === LANGS.FR)
        },
        defaultLangWord() {
            let traduction = this.$store.getters.getTraduction(this.word.traductions)
            if (!traduction) return ""
            return traduction.text
        }
    },
    watch: {
        defaultLangWord(newValue) {
            this.word.text = newValue
        },
        selectedWordTypes(newValue) {
            this.word.type = newValue.map((t) => t.value)
        }
    },
    mounted() {
        this.selectedWordTypes = this.word.type.map((type) => this.wordTypes.find((t) => t.value === type))
        this.loadSound()
    },
    methods: {
        loadSound() {
            this.$nextTick(() => {
                if (this.$refs.audioTag) {
                    this.$refs.audioTag.forEach((tag) => {
                        tag.load()
                    })
                }
            })
        },
        sentenceIsValid(word, text) {
            // last code const lowerWordTitle = word.toLowerCase()
            // last code return text.toLowerCase().includes(lowerWordTitle)
            return text.includes("____") //this is the code for the empty word
        },
        addSentence(lang) {
            let tIdx = this.word.traductions.findIndex((t) => t.lang === lang)
            this.word.traductions[tIdx].sentences.push({
                id: v4(),
                text: "",
                difficulty: 0,
                grade: null,
                soundUrl: "",
                soundPath: ""
            })
        },
        removeSentence(lang, idx) {
            let tIdx = this.word.traductions.findIndex((t) => t.lang === lang)
            this.word.traductions[tIdx].sentences.splice(idx, 1)
        },
        addQuestion(lang) {
            let tIdx = this.word.traductions.findIndex((t) => t.lang === lang)
            this.word.traductions[tIdx].questions.push({
                id: v4(),
                text: "",
                difficulty: 0,
                grade: null,
                soundUrl: "",
                soundPath: ""
            })
        },
        removeQuestion(lang, idx) {
            let tIdx = this.word.traductions.findIndex((t) => t.lang === lang)
            this.word.traductions[tIdx].questions.splice(idx, 1)
        },
        onUpload(e) {
            // has delete image
            if (e.path === null) {
                this.word.imagePath = null
                this.word.imageUrl = null

                return
            }

            this.word.imagePath = e.path
            this.word.imageUrl = e.url
        },
        onSentenceAudioUpload(e, lang, idx) {
            let tIdx = this.word.traductions.findIndex((t) => t.lang === lang)

            if (e.path === null) {
                this.word.traductions[tIdx].sentences[idx].soundPath = null
                this.word.traductions[tIdx].sentences[idx].soundUrl = null

                return
            }

            this.word.traductions[tIdx].sentences[idx].soundPath = e.path
            this.word.traductions[tIdx].sentences[idx].soundUrl = e.url
        },
        onQuestionAudioUpload(e, lang, idx) {
            let tIdx = this.word.traductions.findIndex((t) => t.lang === lang)

            if (e.path === null) {
                this.word.traductions[tIdx].questions[idx].soundPath = null
                this.word.traductions[tIdx].questions[idx].soundUrl = null

                return
            }

            this.word.traductions[tIdx].questions[idx].soundPath = e.path
            this.word.traductions[tIdx].questions[idx].soundUrl = e.url
        },
        getImage() {
            const word = this.word.text.toLowerCase()
            ajax.get(`/helpers/words/${encodeURIComponent(word)}/image?lang=${DEFAULT_LANG}`)
                .then((res) => {
                    if (!res.success) {
                        EventBus.emit(EVENTS.SHOW_FLASH, {
                            title: "Erreur",
                            message: "La génération n'a pas fonctionné",
                            type: "warning"
                        })
                        return
                    }
                    // TODO: delete old file
                    this.word.imagePath = res.path
                    this.word.imageUrl = res.url
                })
                .catch((err) => {
                    //no image
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        title: "Erreur",
                        message: "La génération n'a pas fonctionné",
                        type: "warning"
                    })
                })
        },
        getDefinition(lang) {
            if (!lang) lang = DEFAULT_LANG
            const word = this.word.text.toLowerCase()
            ajax.get(`/helpers/words/${encodeURIComponent(word)}/definition?lang=${lang}`)
                .then((res) => {
                    if (!res.success) {
                        EventBus.emit(EVENTS.SHOW_FLASH, {
                            title: "Erreur",
                            message: "La génération n'a pas fonctionné",
                            type: "warning"
                        })
                        return
                    }
                    if (lang === DEFAULT_LANG) {
                        this.word.description = res.definition
                    }
                    let traductionIdx = this.word.traductions.findIndex((t) => t.lang === lang)
                    this.word.traductions[traductionIdx].description = res.definition
                })
                .catch(() => {
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        title: "Erreur",
                        message: "La génération n'a pas fonctionné",
                        type: "warning"
                    })
                })
        }
    }
}
</script>
