<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/products"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux produits
            </router-link>
            <h1 class="pb-6">Créer un nouveau produit</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit">
                    <product-form
                        :product="product"
                        :errors="errors" />

                    <div class="flex flex-row justify-end items-center pt-6">
                        <button
                            type="submit"
                            class="btn">
                            Créer
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import ProductForm from "views/products/ProductForm"
import { LANGS } from "@globals/constants"

export default {
    name: "create-product",
    components: {
        ProductForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            product: {
                text: "",
                price: 0,
                color: "",
                code: "",
                imagePath: null,
                imageUrl: null,
                pricePointsA: 0,
                unlockAtLevel: 0,
                productCategoryId: null,
                traductions: []
            },
            errors: {},
            isLoading: false
        }
    },
    created() {
        this.product.traductions = Object.values(LANGS).map((lang) => {
            return {
                lang: lang,
                imagePath: null,
                imageUrl: null,
                text: ""
            }
        })
    },
    methods: {
        submit() {
            this.isLoading = true

            this.$store
                .dispatch(types.CREATE_PRODUCT, this.product)
                .then((newProduct) => {
                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: "Le produit a été créé",
                        title: "Produit",
                        type: "success"
                    })
                    this.$router.push(`/products/${newProduct.uuid}`)
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
