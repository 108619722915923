<template>
    <div class="container">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/students"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner aux élèves
            </router-link>
            <h1 class="pb-6">Créer un nouveau élève</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit">
                    <student-form
                        :student="student"
                        :errors="errors" />

                    <div class="flex flex-row justify-end items-center pt-6">
                        <button
                            type="submit"
                            class="btn">
                            Créer
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import StudentForm from "views/students/StudentForm"

export default {
    name: "create-student",
    components: {
        StudentForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            student: {
                firstName: "",
                lastName: "",
                lang: "fr",
                learningLang: "fr",
                teacherId: null,
                schoolId: null,
                shortcode: "",
                gender: null,
                grade: null,
                pointsA: 0,
                pointsXP: 0,
                birthDate: null,
                classrooms: []
            },
            errors: {},
            isLoading: false
        }
    },
    methods: {
        submit() {
            this.isLoading = true

            this.student.teacherId = this.student.teacherId === null ? "" : this.student.teacherId
            this.student.schoolId = this.student.schoolId === null ? "" : this.student.schoolId

            this.$store
                .dispatch(types.CREATE_STUDENT, this.student)
                .then((newStudent) => {
                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: "L'élève a été créé",
                        title: "Élève",
                        type: "success"
                    })
                    this.$router.push(`/students/${newStudent.uuid}`)
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
