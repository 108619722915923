<template>
    <div class="input-container py-6">
        <template v-if="select">
            <label>{{ label }}</label>
            <div class="c__select">
                <select
                    class="form-select"
                    :name="name"
                    @change.prevent="onSelectChange">
                    <option
                        v-if="!hideEmpty"
                        value=""
                        selected>
                        Ne pas spécifier
                    </option>
                    <option
                        v-for="item in data"
                        :key="item[valueKey]"
                        :value="item[valueKey]">
                        {{ getLabel(item) }}
                    </option>
                </select>
            </div>
        </template>
        <template v-else>
            <div class="">
                <label>{{ label }}</label>
                <button
                    class="btn--text"
                    type="button"
                    @click.prevent="showModal = true">
                    Sélectionner
                </button>
            </div>
            <search-modal
                :empty-search="emptySearch"
                @close="showModal = false"
                @search="searchData"
                v-if="showModal">
                <template v-slot="slotProps">
                    <p
                        @click.prevent="onItemClick(item[valueKey])"
                        v-for="item in data"
                        :key="item[valueKey]"
                        class="block w-full m-0 p-1 cursor-pointer"
                        :class="{
                            'bg-red-logo text-white': modelValue.some(i => i[valueKey] === item[valueKey])
                        }">
                        {{ getLabel(item) }}
                    </p>
                    <p
                        class="p-2"
                        v-if="data.length === 0 && !firstSearchDone">
                        Veuillez faire une recherche
                    </p>
                    <p
                        class="p-2"
                        v-if="data.length === 0 && firstSearchDone">
                        Aucun résultats
                    </p>
                </template>
            </search-modal>
        </template>

        <div class="mt-2">
            <div
                class="py-2"
                :class="`${itemIdx === 0 ? '' : 'border-t border-gray-400'}`"
                v-for="(item, itemIdx) in modelValue"
                :key="item[valueKey]">
                <div class="flex flex-row justify-between items-center">
                    <div class="relative w-full flex flex-row justify-start items-center">
                        <slot
                            :item="item"
                            name="item">
                            <p>
                                {{ getLabel(item) }}
                            </p>
                        </slot>
                    </div>
                    <button
                        class="h-full ml-2"
                        type="button"
                        @click.prevent="remove(itemIdx)">
                        <Icons
                            class="h-4 w-auto inline-block text-red-logo"
                            icon="trash" />
                    </button>
                </div>
                <p
                    class="error"
                    v-if="errors && errors[itemIdx]">
                    {{ errors[itemIdx] }}
                </p>
            </div>
            <p v-if="modelValue && modelValue.length === 0">Aucune sélection</p>
        </div>
    </div>
</template>

<script>
import Icons from "components/globals/Icons"
import SearchModal from "components/modals/SearchModal"

export default {
    name: "items-select",
    components: { SearchModal, Icons },
    emits: ["update:modelValue", "search"],
    props: {
        select: {
            type: Boolean,
            default: false
        },
        emptySearch: {
            default: true,
            type: Boolean
        },
        hideEmpty: {
            default: false,
            type: Boolean
        },
        data: Array,
        label: String,
        labelKey: {
            type: [String, Function],
            default: "label"
        },
        valueKey: {
            type: String,
            default: "value"
        },
        modelValue: {
            required: true,
            type: Array
        },
        name: {
            type: String,
            default: "name"
        },
        errors: Object
    },
    data() {
        return {
            showModal: false,
            firstSearchDone: false
        }
    },
    methods: {
        searchData(e) {
            this.$emit("search", e)
            this.firstSearchDone = true
        },
        getLabel(item) {
            if (typeof this.labelKey === "function") {
                return this.labelKey(item)
            }
            return item[this.labelKey]
        },
        onSelectChange(e) {
            const value = parseFloat(e.target.value)
            this.onItemClick(value)
        },
        onItemClick(value) {
            let itemData = this.data.find((s) => s[this.valueKey] === value)
            const exist = this.modelValue.some((s) => s[this.valueKey] === value)
            if (exist) {
                //close modal
                this.showModal = false
                return
            }
            let newArr = [...this.modelValue]
            newArr.push(itemData)

            this.$emit("update:modelValue", newArr)
            this.showModal = false
        },
        remove(idx) {
            let newArr = [...this.modelValue]
            newArr.splice(idx, 1)

            this.$emit("update:modelValue", newArr)
        }
    }
}
</script>
