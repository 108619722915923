<template>
    <div class="container create-word-group">
        <div class="py-6 w-full md:w-2/3 mx-auto">
            <router-link
                to="/word-groups"
                class="text-sm text-gray-600 flex flex-row justify-start items-center">
                <Icons
                    icon="arrow-left"
                    class="w-4 h-auto mr-2"></Icons>
                Retourner a la liste de mots
            </router-link>
            <h1 class="pb-6">Créer une nouvelle liste de mot</h1>
            <loading-container :is-loading="isLoading">
                <form
                    @submit.prevent="submit"
                    @keyup.enter.prevent="submit">
                    <word-group-form
                        :word-group="wordGroup"
                        :errors="errors" />
                    <div class="flex flex-row justify-end items-center pt-6">
                        <button
                            type="submit"
                            class="btn">
                            Créer
                        </button>
                    </div>
                </form>
            </loading-container>
        </div>
    </div>
</template>

<script>
import * as types from "store/types"
import LoadingContainer from "components/LoadingContainer"
import Icons from "components/globals/Icons"
import EventBus, { EVENTS } from "@/eventbus"
import WordGroupForm from "views/word-groups/WordGroupForm"
import { LANGS } from "@globals/constants"

export default {
    name: "create-word-group",
    components: {
        WordGroupForm,
        Icons,
        LoadingContainer
    },
    data() {
        return {
            wordGroup: {
                active: false,
                text: "",
                wordIds: [],
                isCurrent: false,
                externalCode: null,
                orderIdx: 0, //todo grab last
                grade: 1,
                traductions: []
            },
            errors: {},
            isLoading: false
        }
    },
    created() {
        this.wordGroup.traductions = Object.values(LANGS).map((lang) => {
            return {
                lang: lang,
                text: ""
            }
        })
    },
    methods: {
        submit() {
            this.isLoading = true
            this.$store
                .dispatch(types.CREATE_WORD_GROUP, this.wordGroup)
                .then((newWord) => {
                    this.isLoading = false
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        message: "La liste de mot a été créé",
                        title: "Liste de mot",
                        type: "success"
                    })
                    this.$router.push(`/word-groups/${newWord.uuid}`)
                })
                .catch((err) => {
                    this.isLoading = false
                    this.errors = err.errors || {}
                })
        }
    }
}
</script>
