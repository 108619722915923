<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <loading-container :is-loading="isLoading">
            <form @submit.prevent="importWords">
                <h5>Veuillez copier-coller un fichier CSV dans le contenu</h5>
                <div class="input-container">
                    <label>Contenu</label>
                    <div class="c__input">
                        <textarea
                            v-model="csv"
                            name="csv"></textarea>
                    </div>
                    <p
                        class="error"
                        v-if="errors.csv">
                        {{ errors.csv }}
                    </p>
                </div>
                <div class="flex flex-row justify-end items-center mt-6">
                    <button
                        class="btn"
                        type="submit">
                        Envoyer
                    </button>
                </div>
            </form>
        </loading-container>
    </modal>
</template>
<script>
import Modal from "components/globals/Modal"
import LoadingContainer from "components/LoadingContainer"
import ajax from "config/ajax"

export default {
    components: {
        LoadingContainer,
        Modal
    },
    emits: ['close'],
    data() {
        return {
            csv: "",
            errors: {},
            isLoading: false
        }
    },
    methods: {
        importWords() {
            this.isLoading = true
            ajax.post("/words/import", { csv: this.csv })
                .then((res) => {
                    this.isLoading = false
                })
                .catch((err) => {
                    this.errors = err.errors || {}
                    this.isLoading = false
                })
        }
    }
}
</script>
