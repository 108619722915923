<template>
    <div class="words">
        <pagination-layout
            :data="wordGroups"
            create-title="Créer une nouvelle liste de mot"
            @search="onSearch"
            title="Liste de mots"
            create-url="/word-groups/create">
            <template v-slot:header>
                <custom-th> Ordre </custom-th>
                <custom-th>
                    {{ $t(`global.langs.fr`) }}
                </custom-th>
                <custom-th> Niveau </custom-th>
                <custom-th> Année scolaire </custom-th>
                <custom-th> Nombre de mots </custom-th>
                <custom-th> Liste intelligente </custom-th>
                <custom-th> Code AlloProf </custom-th>
                <custom-th>
                    <span class="sr-only">Modifier</span>
                </custom-th>
            </template>

            <template v-slot:data="{ currentPage }">
                <tr
                    v-for="wordGroup in currentPage"
                    :key="wordGroup.id">
                    <custom-td>
                        {{ wordGroup.orderIdx }}
                    </custom-td>
                    <custom-td>
                        {{ wordGroup.text }}
                    </custom-td>
                    <custom-td>
                        {{ $t(`global.difficulty.${wordGroup.difficulty}`) }}
                    </custom-td>
                    <custom-td>
                        {{ wordGroup.grade }}
                    </custom-td>
                    <custom-td>
                        <span v-if="wordGroup.wordIds">
                            {{ wordGroup.wordIds.length }}
                        </span>
                    </custom-td>
                    <custom-td>
                        {{ wordGroup.smartType ? "Oui" : "Non" }}
                    </custom-td>

                    <custom-td>
                        <span v-if="wordGroup.externalCode">
                            {{wordGroup.externalCode}}
                        </span>
                    </custom-td>

                    <custom-td>
                        <router-link
                            :to="`/word-groups/${wordGroup.uuid}`"
                            class="text-red-logo hover:text-red-logo-dark text-right w-full block">
                            Modifier
                        </router-link>
                    </custom-td>
                </tr>
            </template>
        </pagination-layout>
    </div>
</template>

<script>
import PaginationLayout from "views/layouts/PaginationLayout"
import CustomTh from "components/tables/CustomTh"
import { sortBy } from "lodash"
import CustomTd from "components/tables/CustomTd"
import ajax from "config/ajax"

export default {
    name: "word-groups",
    components: {
        CustomTd,
        CustomTh,
        PaginationLayout
    },
    data() {
        return {
            wordGroups: []
        }
    },
    methods: {
        onSearch(searchValue) {
            ajax.get(`/word-groups/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.wordGroups = sortBy(res.wordGroups, "text")
            })
        }
        /* getTableWords(wordGroup) {
       return this.$store.state.data.words.filter(w => wordGroup.words.some(_w => _w.id === w.id)).map(w => this.$store.getters.getWordTraduction(w)).join(',')
     } */
    }
}
</script>
