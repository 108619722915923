<template>
    <div class="students">
        <pagination-layout
            title="Éleves"
            create-title="Créer un nouveau élève"
            create-url="/students/create"
            @search="onSearch"
            :data="students">
            <template v-slot:header>
                <custom-th> Nom </custom-th>
                <custom-th> Nom d'utilisateur </custom-th>
                <custom-th> Langue </custom-th>
              <!--  <custom-th> Langue d'apprentissage </custom-th> -->
                <custom-th>
                    <span class="sr-only">Modifier</span>
                </custom-th>
            </template>

            <template v-slot:data="{ currentPage }">
                <tr
                    v-for="student in currentPage"
                    :key="student.id">
                    <custom-td>
                        {{ student.name }}
                    </custom-td>
                    <custom-td>
                        {{ student.username }}
                    </custom-td>
                    <custom-td>
                        {{ $t(`global.langs.${student.lang}`) }}
                    </custom-td>
                    <custom-td>
                        {{ $t(`global.langs.${student.learningLang}`) }}
                    </custom-td>
                    <custom-td>
                        <router-link
                            :to="`/students/${student.uuid}`"
                            class="text-red-logo hover:text-red-logo-dark text-right w-full block">
                            Modifier
                        </router-link>
                    </custom-td>
                </tr>
            </template>
        </pagination-layout>
    </div>
</template>

<script>
import PaginationLayout from "views/layouts/PaginationLayout"
import CustomTd from "components/tables/CustomTd"
import CustomTh from "components/tables/CustomTh"
import ajax from "config/ajax"

export default {
    name: "students",
    components: {
        CustomTh,
        PaginationLayout,
        CustomTd
    },
    data() {
        return {
            students: []
        }
    },
    methods: {
        onSearch(searchValue) {
            ajax.get(`/students/search`, {
                params: {
                    search: searchValue
                }
            }).then((res) => {
                this.students = res.students
            })
        }
        /* getTeacherName(teacherId) {
      let teacherName = 'Aucun'
      if (!teacherId) return teacherName
      Object.values(this.$store.state.data.schoolsById).forEach(school => {
        if (!school.employees) return
        school.employees.forEach(employee => {
          if (employee.isTeacher && employee.id === teacherId) {
            teacherName = employee.name
          }
        })
      })
      return teacherName
    }, */
    }
}
</script>
