<template>
    <div>
        <p
            class="error"
            v-if="productCategory && productCategory.slug === PRODUCT_CATEGORIES.BADGE">
            Une modification ou ajout de badge requiert un changement de code. Veuillez contacter Gilcode
        </p>
        <p
            class="error"
            v-if="productCategory && productCategory.slug === PRODUCT_CATEGORIES.MAIN_BACKGROUND">
            Une modification ou ajout de fond d'écran requiert un changement de code. Veuillez contacter Gilcode
        </p>
        <p
            class="error"
            v-if="productCategory && productCategory.slug === PRODUCT_CATEGORIES.BACKGROUND">
            Une modification ou ajout de fond requiert un changement de code. Veuillez contacter Gilcode
        </p>
        <div class="md:grid gap-3 grid-cols-1 md:grid-cols-2">
            <div class="input-container">
                <label>Nom</label>
                <div class="c__input">
                    <input
                        disabled
                        type="text"
                        name="text"
                        :value="product.text" />
                </div>
                <p
                    class="error"
                    v-if="errors.text">
                    {{ errors.text }}
                </p>
            </div>
            <div class="input-container">
                <label>Niveau pour avoir ce produit</label>
                <div class="c__input">
                    <input
                        type="number"
                        name="unlockAtLevel"
                        v-model="product.unlockAtLevel" />
                </div>
                <p
                    class="error"
                    v-if="errors.unlockAtLevel">
                    {{ errors.unlockAtLevel }}
                </p>
            </div>
        </div>

        <div class="md:grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <div class="input-container">
                <label>Prix réel (cad)</label>
                <div class="c__input">
                    <input
                        type="number"
                        name="price"
                        v-model="product.price" />
                </div>
                <p
                    class="error"
                    v-if="errors.price">
                    {{ errors.price }}
                </p>
            </div>
            <div class="input-container">
                <label>Prix K</label>
                <div class="c__input">
                    <input
                        type="number"
                        name="pricePointsA"
                        v-model="product.pricePointsA" />
                </div>
                <p
                    class="error"
                    v-if="errors.pricePointsA">
                    {{ errors.pricePointsA }}
                </p>
            </div>
            <item-select
                select
                hide-empty
                v-model="product.productCategoryId"
                :data="$store.getters.dataToSelector($store.state.data.productCategories, 'title', 'id')"
                label="Catégorie"
                :error="errors.productCategoryId" />
        </div>
        <div class="md:grid gap-3 grid-cols-1 md:grid-cols-3">
            <div class="input-container">
                <label>Image du produit</label>
                <UploadImage
                    :path="product.imagePath"
                    :url="product.imageUrl"
                    file-folder="products"
                    @upload="onUpload"
                    preview-class="w-24 h-24 object-contain block" />
                <p
                    class="error"
                    v-if="errors.imagePath">
                    {{ errors.imagePath }}
                </p>
            </div>
            <div class="input-container">
                <label>Code</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="code"
                        v-model="product.code" />
                </div>
                <p
                    class="error"
                    v-if="errors.code">
                    {{ errors.code }}
                </p>
            </div>
            <div class="input-container">
                <label>Couleur</label>
                <div class="c__input">
                    <input
                        type="text"
                        name="color"
                        v-model="product.color" />
                </div>
                <p
                    class="error"
                    v-if="errors.color">
                    {{ errors.color }}
                </p>
            </div>
        </div>

        <div>
            <div
                v-for="traduction in product.traductions"
                :key="traduction.lang"
                class="bg-gray-200 p-6 my-6">
                <div class="flex flex-row justify-between items-center">
                    <h3>
                        {{ $t(`global.langs.${traduction.lang}`) }}
                    </h3>
                    <div class="input-container">
                        <div class="c__input flex flex-row justify-start items-center">
                            <input
                                :id="`traduction-${traduction.lang}-active`"
                                type="checkbox"
                                name="active"
                                v-model="traduction.active" />
                            <label :for="`traduction-${traduction.lang}-active`">Activé</label>
                        </div>
                    </div>
                </div>
                <div class="input-container">
                    <label>Nom</label>
                    <div class="c__input">
                        <input
                            type="text"
                            name="name"
                            v-model="traduction.text" />
                    </div>
                    <p
                        class="error"
                        v-if="errors.text">
                        {{ errors.text }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemsSelect from "components/forms/ItemsSelect"
import ItemSelect from "components/forms/ItemSelect"
import UploadImage from "components/uploads/UploadImage"
import { PRODUCT_CATEGORIES } from "@globals/constants"

export default {
    name: "product-form",
    components: {
        UploadImage,
        ItemSelect,
        ItemsSelect
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            PRODUCT_CATEGORIES
        }
    },
    computed: {
        defaultLangWord() {
            let traduction = this.$store.getters.getTraduction(this.product.traductions)
            if (!traduction) return ""
            return traduction.text
        },
        productCategory() {
            return this.$store.state.data.productCategories.find((pC) => pC.id === this.product.productCategoryId)
        }
    },
    watch: {
        defaultLangWord(newValue) {
            this.product.text = newValue
        }
    },
    methods: {
        onUpload(e) {
            // has delete image
            if (e.path === null) {
                this.product.imagePath = null
                this.product.imageUrl = null

                return
            }

            this.product.imagePath = e.path
            this.product.imageUrl = e.url
        }
    }
}
</script>
