<template>
    <div class="input-container py-6">
        <template v-if="select">
            <label>{{ label }}</label>
            <div class="c__select">
                <select
                    :disabled="disabled"
                    v-model="value"
                    :name="name"
                    class="form-select">
                    <option
                        v-if="!hideEmpty"
                        value=""
                        selected>
                        Ne pas spécifier
                    </option>
                    <option
                        v-for="item in data"
                        :key="getValue(item)"
                        :value="getValue(item)">
                        {{ getLabel(item) }}
                    </option>
                </select>
            </div>
        </template>
        <template v-else>
            <div class="">
                <label>{{ label }}</label>
                <button
                    v-if="!disabled"
                    class="btn--text"
                    :class="{ 'pointer-events-none': disabled }"
                    type="button"
                    @click.prevent="showModal = true">
                    Sélectionner
                </button>
            </div>
            <search-modal
                :empty-search="emptySearch"
                @close="showModal = false"
                @search="searchData"
                v-if="showModal">
                <template v-slot="slotProps">
                    <p
                        @click.prevent="onItemClick(getValue(item))"
                        v-for="item in data"
                        :key="getValue(item)"
                        class="block w-full m-0 p-1 cursor-pointer"
                        :class="{
                            'bg-red-logo text-white': value === getValue(item)
                        }">
                        {{ getLabel(item) }}
                    </p>
                    <p
                        class="p-2"
                        v-if="data.length === 0 && !firstSearchDone">
                        Veuillez faire une recherche
                    </p>
                    <p
                        class="p-2"
                        v-if="data.length === 0 && firstSearchDone">
                        Aucun résultats
                    </p>
                </template>
            </search-modal>

            <p v-if="selectedItem">
                {{ getLabel(selectedItem) }}
            </p>
            <p v-else>Aucune sélection</p>
        </template>
        <p
            class="error"
            v-if="error">
            {{ error }}
        </p>
    </div>
</template>

<script>
import SearchModal from "components/modals/SearchModal"

export default {
    name: "item-select",
    components: { SearchModal },
    emits: ["update:modelValue", "search"],
    props: {
        select: {
            default: false,
            type: Boolean
        },
        emptySearch: {
            default: true,
            type: Boolean
        },
        hideEmpty: {
            default: false,
            type: Boolean
        },
        label: String,
        data: Array,
        disabled: {
            default: false,
            type: Boolean
        },
        valueKey: {
            type: String,
            default: "value"
        },
        labelKey: {
            type: [String, Function],
            default: "label"
        },
        modelValue: [Number, String],
        name: {
            type: String,
            default: "item"
        },
        error: {
            default: null,
            type: [String, null]
        }
    },
    data() {
        return {
            firstSearchDone: false,
            showModal: false
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit("update:modelValue", value)
            }
        },
        selectedItem() {
            return this.data.find((d) => this.getValue(d) === this.modelValue)
        }
    },
    methods: {
        searchData(e) {
            this.$emit("search", e)
            this.firstSearchDone = true
        },
        onItemClick(value) {
            //unselect
            if (this.value === value) {
                this.value = ""
                this.showModal = false
                return
            }
            this.value = value
            this.showModal = false
        },
        getValue(item) {
            if (!this.valueKey) {
                return item
            }
            return item[this.valueKey]
        },
        getLabel(item) {
            if (typeof this.labelKey === "function") {
                return this.labelKey(item)
            }
            if (!this.labelKey) {
                return item
            }
            return item[this.labelKey]
        }
    }
}
</script>
